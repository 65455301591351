import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CatTableRow,
  MedicationBrand,
  Requisition
} from '../../../model/models';
import { ApplicationDataService } from '../../../service/data/application-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FipAdminApiService } from '../../../service/api/fip-admin-api.service';
import {
  countries,
  requisitionEmails,
  State,
  treatmentTypes
} from '../../../model/constants';
import { catchError, map, Observable, of, startWith, take, timer } from 'rxjs';
import {
  AnalyticsEventEnum,
  RequisitionPromos,
  RequisitionStatus,
  requisitionStatuses
} from '../../../model/enums';
import {
  findStateByAbbreviation,
  generateRequisitionBrands
} from '../../../service/utils/ui-utils';
import { AnalyticsService } from '../../../service/analytics/analytics.service';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-requisition-form',
  templateUrl: './requisition-form.component.html',
  styleUrls: ['./requisition-form.component.scss']
})
export class RequisitionFormComponent implements OnInit {
  protected readonly countries = countries;
  activeTab = 0;
  medicationBrands: MedicationBrand[] | undefined;
  filteredSelectionBrands: MedicationBrand[] | undefined;

  @Input()
  requisition: Requisition | undefined;

  @Input()
  cats: CatTableRow[] | undefined;

  @Output()
  requisitionSave = new EventEmitter<Requisition>();

  @Output()
  requisitionClose = new EventEmitter<void>();

  catIdNames: { name: string; id: string }[] = [];

  shippingSpeeds = [
    {
      name: 'Ground',
      time: '(3-5 days)',
      price: 10.0
    },
    {
      name: '2nd Day',
      time: '(2 days)',
      price: 35.0
    },
    {
      name: 'Next Day',
      time: '(1 day)',
      price: 45.0
    },
    {
      name: 'Next Day Saturday',
      time: '(1 day on Friday)',
      price: 70.0
    }
  ];

  requisitionForm = new FormGroup({
    status: new FormControl<string | undefined>(undefined),
    trackingNumber: new FormControl<string | undefined>(undefined),
    treatmentType: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    cat: new FormControl<{ name: string; id: string } | undefined>(
      undefined,
      Validators.required
    ),
    quantity: new FormControl<number>(0, [
      Validators.required,
      Validators.min(1)
    ]),
    medicationBrand: new FormControl<MedicationBrand | undefined>(
      undefined,
      Validators.required
    ),
    firstName: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    lastName: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    addressLine1: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    addressLine2: new FormControl<string | undefined>(undefined),
    country: new FormControl<string | undefined>(
      'United States',
      Validators.required
    ),
    city: new FormControl<string | undefined>(undefined, Validators.required),
    state: new FormControl<string | undefined>(undefined, Validators.required),
    zipCode: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    email: new FormControl<string | undefined>(undefined, Validators.required),
    phoneNumber: new FormControl<string | undefined>(undefined, [
      Validators.maxLength(11)
    ]),
    phoneNumberUsage: new FormControl<boolean>(false),
    shippingSpeed: new FormControl<string | undefined>(
      this.shippingSpeeds[0].name,
      Validators.required
    ),
    total: new FormControl<number | undefined>(0, [
      Validators.required,
      Validators.min(1)
    ]),
    disclaimer: new FormControl<boolean | undefined>(undefined, [
      Validators.required,
      Validators.requiredTrue
    ]),
    promoCodes: new FormControl<string[]>([])
  });

  states: State[] = this.countries[0].states.map((state) => state);
  filteredStates: Observable<State[]>;

  // Certain pills can be only purchased in specific quantities
  pillsPackQuantity = [
    {
      name: 'Karma',
      quantity: 5
    },
    {
      name: 'Karma 1kg',
      quantity: 5
    },
    {
      name: 'Karma 2kg',
      quantity: 5
    },
    {
      name: 'Capella 1kg',
      quantity: 5
    },
    {
      name: 'Capella 2kg',
      quantity: 5
    },
    {
      name: 'Capella 5kg',
      quantity: 2
    }
  ];

  loading = false;
  infoBannerText: string | undefined;
  availablePromoCodes: string[] = [];

  constructor(
    private applicationDataService: ApplicationDataService,
    private fipAdminApiService: FipAdminApiService,
    private analyticsService: AnalyticsService,
    private dialog: MatDialog
  ) {
    this.applicationDataService
      .getApplicationData()
      .pipe(takeUntilDestroyed())
      .subscribe((applicationData) => {
        if (applicationData?.medicationBrands) {
          this.medicationBrands = generateRequisitionBrands(
            applicationData?.medicationBrands
          );
        }
      });

    this.requisitionForm.controls.country.disable();

    this.filteredStates = this.requisitionForm.controls.state.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.states.filter((state) => {
          value = value || '';
          return state.name.toLowerCase().includes(value.toLowerCase());
        })
      )
    );
  }

  ngOnInit() {
    if (this.cats) {
      const originalSize = this.cats.length;
      this.cats = this.cats.filter(
        (cat) => cat.catProMemberDTO && cat.catProMemberDTO.status == 'Active'
      );

      if (originalSize !== this.cats.length || this.cats.length === 0) {
        this.infoBannerText = 'Pro membership is required to use this feature.';
      }

      this.catIdNames = this.cats.map((cat) => ({
        name: cat.name,
        id: cat.id
      }));
    }

    if (this.requisition) {
      this.populateRequisitionForm(this.requisition);
    }
  }

  treatmentTypeChange(treatmentType: string) {
    this.filterBrands(treatmentType);
    this.medicationBrand.reset();
    this.quantity.reset();
    this.promoChanges();
  }

  filterBrands(treatmentType: string) {
    this.filteredSelectionBrands = this.medicationBrands?.filter(
      (brand) => brand.type === treatmentType
    );
  }

  save() {
    const cat = this.requisitionForm.controls.cat.value;
    if (cat) {
      this.adjustPromoValues();

      const requisition: any = {
        id: this.requisition?.id,
        ...this.requisitionForm.getRawValue(),
        catId: cat.id,
        catName: cat.name,
        status: this.requisition?.id
          ? this.status.value
          : RequisitionStatus.SUBMITTED
      };

      this.loading = true;

      this.fipAdminApiService
        .saveRequisition(requisition)
        .pipe(
          catchError(() => {
            return of(undefined);
          })
        )
        .subscribe((requisition) => {
          if (!requisition) {
            this.loading = false;
            return;
          }

          timer(1000)
            .pipe(take(1))
            .subscribe(() => {
              this.loading = false;
            });

          //this.requisition = requisition;
          this.analyticsService.sendAnalyticsEvent(
            this.applicationDataService.getApplicationData().value,
            undefined,
            AnalyticsEventEnum.SAVE_REQUISITION
          );
          this.requisitionSave.emit(requisition);
        });

      //console.log(requisition);
    }
  }

  back() {
    this.requisitionClose.emit();
  }

  roundNumberUp() {
    if (this.treatmentType.value === 'Pill') {
      const currentNumber = this.quantity.value;
      if (currentNumber && currentNumber > 0) {
        const numberToRoundTo = this.getQuantities();
        if (numberToRoundTo && numberToRoundTo) {
          this.quantity.setValue(
            Math.round(currentNumber / numberToRoundTo) * numberToRoundTo
          );
        }
      }
    }

    this.calculateTotal();
  }

  get treatmentType() {
    return this.requisitionForm.controls.treatmentType;
  }

  get isShot() {
    return this.treatmentType.value === 'Shot';
  }

  get isPill() {
    return this.treatmentType.value === 'Pill';
  }

  get quantity() {
    return this.requisitionForm.controls.quantity;
  }
  get medicationBrand() {
    return this.requisitionForm.controls.medicationBrand;
  }

  get shippingSpeed() {
    return this.requisitionForm.controls.shippingSpeed;
  }

  get status() {
    return this.requisitionForm.controls.status;
  }

  get cat() {
    return this.requisitionForm.controls.cat;
  }

  get promoCodes() {
    return this.requisitionForm.controls.promoCodes;
  }

  getQuantities() {
    return this.pillsPackQuantity.find(
      (pill) => pill.name === this.medicationBrand.value?.name
    )?.quantity;
  }

  calculateTotal() {
    const usd = this.medicationBrand.value?.usd;
    const shippingPrice = this.shippingSpeeds.find(
      (speed) => speed.name === this.shippingSpeed.value
    )?.price;

    if (usd && this.quantity.value && shippingPrice) {
      const total = usd * this.quantity.value + shippingPrice;
      this.requisitionForm.controls.total.setValue(total);
    }
  }

  protected readonly requisitionStatuses = requisitionStatuses;

  private populateRequisitionForm(requisition: Requisition) {
    const formControls = this.requisitionForm.controls;
    formControls.status.setValue(requisition.status);
    formControls.trackingNumber.setValue(requisition.trackingNumber);
    //formControls.processedOn.setValue(requisition.trackingNumber);

    formControls.treatmentType.setValue(requisition.medicationBrand.type);
    // Need to trigger the filteredSelectionBrand set value
    this.treatmentTypeChange(requisition.medicationBrand.type);
    const requisitionBrand = this.medicationBrands?.find(
      (brand) => brand.name === requisition.medicationBrand.name
    );
    formControls.medicationBrand.setValue(requisitionBrand);

    this.catIdNames = [
      {
        name: requisition.catName,
        id: requisition.catId
      },
      ...this.catIdNames
    ];
    formControls.cat.setValue(this.catIdNames[0]);

    formControls.email.setValue(requisition.email);
    formControls.firstName.setValue(requisition.firstName);
    formControls.lastName.setValue(requisition.lastName);
    formControls.city.setValue(requisition.city);
    formControls.state.setValue(requisition.state);
    formControls.country.setValue(requisition.country);
    formControls.zipCode.setValue(requisition.zipCode);
    formControls.addressLine1.setValue(requisition.addressLine1);
    formControls.addressLine2.setValue(requisition.addressLine2);
    formControls.phoneNumber.setValue(requisition.phoneNumber);
    formControls.phoneNumberUsage.setValue(requisition.phoneNumberUsage);
    formControls.disclaimer.setValue(requisition.disclaimer);

    formControls.quantity.setValue(requisition.quantity);
    formControls.total.setValue(requisition.total);
    formControls.promoCodes.setValue(requisition.promoCodes);

    formControls.shippingSpeed.setValue(requisition.shippingSpeed);

    this.requisitionForm.disable();
    this.requisitionForm.controls.status.enable();
    this.requisitionForm.controls.trackingNumber.enable();
  }

  markPaid() {
    this.status.setValue(RequisitionStatus.PAID);
    this.save();
  }

  protected readonly RequisitionStatus = RequisitionStatus;

  cancelRequisition() {
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 350,
      width: '75vw',
      maxWidth: 750,
      ariaModal: true,
      autoFocus: false,
      data: {
        action: 'cancel this requisition?'
      }
    });
    confirmationDialog.afterClosed().subscribe((decision: boolean) => {
      if (decision) {
        this.analyticsService.sendAnalyticsEvent(
          this.applicationDataService.getApplicationData().value,
          undefined,
          AnalyticsEventEnum.NEW_REQUISITION
        );
        this.status.setValue(RequisitionStatus.CANCELLED);
        this.save();
      }
    });
  }

  getRequisitionEmail() {
    if (this.requisition && this.requisition.id) {
      return requisitionEmails[
        Number(this.requisition.id) % requisitionEmails.length
      ];
    }
    return 'Error occurred, please contact admins.';
  }

  checkPromos() {
    if (this.cat && this.cat.value?.id) {
      this.fipAdminApiService
        .getRequisitionPromoCodes(this.cat.value?.id)
        .subscribe((promoCodes) => {
          this.availablePromoCodes = promoCodes;
          this.promoChanges();
        });
    }
  }

  promoChanges() {
    if (
      this.isShot &&
      this.availablePromoCodes.includes(RequisitionPromos.FREE_VIAL)
    ) {
      this.promoCodes.setValue([RequisitionPromos.FREE_VIAL]);
    }

    if (
      this.isPill &&
      this.availablePromoCodes.includes(RequisitionPromos.FREE_PILL_BAG)
    ) {
      this.promoCodes.setValue([RequisitionPromos.FREE_PILL_BAG]);
    }
  }

  private adjustPromoValues() {
    if (
      this.isShot &&
      this.promoCodes.value?.includes(RequisitionPromos.FREE_VIAL)
    ) {
      this.quantity.setValue((this.quantity.value || 0) + 1);
    }

    if (
      this.isPill &&
      this.promoCodes.value?.includes(RequisitionPromos.FREE_PILL_BAG)
    ) {
      this.quantity.setValue(
        (this.quantity.value || 0) + (this.getQuantities() || 0)
      );
    }
  }

  protected readonly RequisitionPromos = RequisitionPromos;

  checkState($event: any) {
    this.requisitionForm.controls.state.setValue(
      findStateByAbbreviation($event.target.value, this.states)
    );
  }

  protected readonly treatmentTypes = treatmentTypes;
}
